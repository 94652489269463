<template>
  <v-container fluid no-gutters>
    <B2cTabs/>
    <div class="text-lg font-medium mt-6">Kiosk Configuration</div>
    <div class="mb-8 bg-white pa-4 bordered rounded-lg">
      <v-form ref="form" :value="valid">
        <p class="mb-1">
          Modules
        </p>
        <v-row>
          <v-col cols="12" lg="3" md="4">
            <v-checkbox
                v-model="configurations.enable_facility_bookings"
                class="qp-custom-checkbox mt-5 no-height"
                hide-details
                label="Enable Facility Bookings"
            />
          </v-col>
          <v-col cols="12" lg="3" md="4">
            <v-checkbox
                v-model="configurations.enable_event_bookings"
                class="qp-custom-checkbox mt-5 no-height"
                hide-details
                label="Enable Event Bookings"
            />
          </v-col>
          <v-col cols="12" lg="3" md="4">
            <v-checkbox
                v-model="configurations.enable_workshop_bookings"
                class="qp-custom-checkbox mt-5 no-height"
                hide-details
                label="Enable Academy Bookings"
            />
          </v-col>
        </v-row>
        <template v-if="configurations.enable_facility_bookings">
          <v-divider class="my-4"/>
          <p class="mb-1">
            Facility Booking Configuration
          </p>
          <v-row>
            <v-col cols="12" lg="2" md="3">
              <v-checkbox
                  v-model="configurations.enable_service"
                  class="qp-custom-checkbox mt-5 no-height"
                  hide-details
                  label="Enable Service"
              />
            </v-col>
            <v-col v-if="showDefaultService" cols="12" lg="2" md="3">
              <label for="">Default Service*</label>
              <v-select
                  v-model="configurations.venue_service_id"
                  :items="venueServices"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'Select Default Service']"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details="auto"
                  item-text="name"
                  item-value="venue_service_id"
                  outlined
                  required
              ></v-select>
            </v-col>
            <v-col v-if="showEnableFacility" cols="12" lg="2" md="3">
              <v-checkbox
                  v-model="configurations.enable_facility"
                  class="qp-custom-checkbox mt-5 no-height"
                  hide-details
                  label="Enable Facility"
              />
            </v-col>
            <v-col v-if="showDefaultFacility" cols="12" lg="2" md="3">
              <label for="">Default Facility*</label>
              <v-select
                  v-model="configurations.facility_id"
                  :items="facilities"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'Select a Default Facility']"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details="auto"
                  item-text="name"
                  item-value="id"
                  outlined
                  required
              ></v-select>
            </v-col>
            <v-col v-if="showEnableProducts" cols="12" lg="2" md="3">
              <v-checkbox
                  v-model="configurations.enable_products"
                  class="qp-custom-checkbox mt-5 no-height"
                  hide-details
                  label="Enable Products"
              />
            </v-col>
            <v-col v-if="showDefaultProduct" cols="12" lg="2" md="3">
              <label for="">Default Product*</label>
              <v-select
                  v-model="configurations.product_id"
                  :items="tickets"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'Select a Default Product']"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  dense
                  hide-details="auto"
                  item-text="name"
                  item-value="id"
                  outlined
                  required
              ></v-select>
            </v-col>
            <v-col cols="12" lg="2" md="3">
              <v-checkbox
                  v-model="configurations.enable_date"
                  class="qp-custom-checkbox mt-5 no-height"
                  hide-details
                  label="Enable Date"
              />
            </v-col>
          </v-row>
        </template>
        <v-divider class="my-4"/>
        <p class="mb-1">
          Customer Configurations
        </p>
        <v-row>
          <v-col cols="12" lg="2" md="3">
            <v-checkbox
                v-model="configurations.enable_auto_fetch_customer"
                class="qp-custom-checkbox mt-2 no-height"
                hide-details
                label="Enable Customer Autofill"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          class="ma-2 white--text teal-color"
          height="45"
          text
          @click="submitData()"
      >Save
      </v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import B2cTabs from "@/views/B2CConfigurations/B2cTabs.vue";

export default {
  components: {B2cTabs},
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    this.btnShow = true;
    this.getConfigurations();
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter(
          (service) => service.name != "POS"
      );
    },
    facility() {
      let fac = {};
      if (!this.configurations.enable_facility && this.configurations.facility_id) {
        fac = this.facilities.find(facility => facility.facility_id == this.configurations.facility_id);
      }
      return fac ? fac : {};
    },
    showDefaultService() {
      return !this.configurations.enable_service;
    },
    showEnableFacility() {
      return !this.configurations.enable_service && this.configurations.venue_service_id;
    },
    showDefaultFacility() {
      return !this.configurations.enable_service && this.configurations.venue_service_id && !this.configurations.enable_facility;
    },
    showEnableProducts() {
      return !this.configurations.enable_service && this.configurations.venue_service_id && !this.configurations.enable_facility && this.configurations.facility_id;
    },
    showDefaultProduct() {
      return !this.configurations.enable_service && this.configurations.venue_service_id && !this.configurations.enable_facility && this.configurations.facility_id && !this.configurations.enable_products;
    }
  },
  data() {
    return {
      configurations: {
        enable_facility_bookings: true,
        enable_event_bookings: true,
        enable_workshop_bookings: true,
        enable_service: true,
        enable_facility: true,
        enable_products: true,
        enable_date: true,
        facility_id: null,
        venue_service_id: null,
        product_id: null,
        enable_auto_fetch_customer: false,
      },
      facilities: [],
      tickets: [],
      valid: true
    };
  },
  watch: {
    "configurations.venue_service_id": function () {
      this.getFacilities();
    },
    "configurations.facility_id": function () {
      this.getTickets();
    },
    "configurations.enable_service": function () {
      if (this.configurations.enable_service) {
        this.configurations.venue_service_id = null;
        this.configurations.facility_id = null;
        this.configurations.product_id = null;
        this.configurations.enable_facility = true;
        this.configurations.enable_products = true;
      }
    },
    "configurations.enable_facility": function () {
      if (this.configurations.enable_facility) {
        this.configurations.facility_id = null;
        this.configurations.product_id = null;
        this.configurations.enable_products = true;
      } else {
        this.getFacilities();
      }
    },
    "configurations.enable_products": function () {
      if (this.configurations.enable_products) {
        this.configurations.product_id = null;
      } else {
        this.getTickets();
      }
    },
  },
  methods: {
    getConfigurations() {
      this.showLoader("Loading");

      this.$http
          .get("venues/configurations/kiosk/configuration")
          .then((response) => {
            if (response.status == 200 && response.data.data) {
              this.configurations = response.data.data;
            }
          })
          .catch((error) => {
            this.showError(error)
          })
          .finally(() => {
            this.hideLoader();
          });
    },
    getFacilities() {
      if (!this.configurations.venue_service_id) {
        this.facilities = [];
        return;
      }
      this.$http
          .get("venues/facilities/venue-service-id/" + this.configurations.venue_service_id)
          .then((response) => {
            if (response.status == 200) {
              this.facilities = response.data.data;
            }
          })
          .catch((error) => {
            this.showError(error)
          })
          .finally(() => {
            this.hideLoader();
          });
    },
    getTickets() {
      if (!this.configurations.facility_id) {
        this.tickets = [];
        return;
      }
      this.$http
          .get(`venues/facilities/${this.configurations.facility_id}/tickets`)
          .then((response) => {
            if (response.status == 200) {
              this.tickets = response.data.data;
            }
          })
          .catch((error) => {
            this.showError(error)
          })
          .finally(() => {
            this.hideLoader();
          });
    },

    submitData() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader("Loading");

      let data = this.configurations;

      this.$http
          .post(`venues/configurations/kiosk/configuration`, data)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.showSuccess("Configuration saved successfully.");
              this.getConfigurations();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      this.hideLoader();
    },
  },
};
</script>

<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}

.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
</style>
